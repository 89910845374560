import { navigate } from "@reach/router";
import { getToken } from "../services/keepAuth";

export const apiBase = window.location.host.includes("localhost") ? "http://localhost:3012" : "https://api.gazmir.ro";
// export const apiBase = "http://api.gazmir.ro";

// function RequestException(response) {
//   this.response = response
// }

// const basePath = ""
export const appBasePath = "";

export interface ApiResponse<T> {
  type: boolean;
  data: T;
}

export function convertFilterToRequestParams(filters: any) {
  return Object.keys(filters)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(filters[key]))
    .join("&")
    .replace(/%20/g, "+");
}

export function checkStatus<T>(response: Response): Promise<T> {
  // console.log('Response:', response);
  if (response.status >= 200 && response.status < 300) {
    return response.json();
  }
  if (response.status === 403) {
    // logout();
    navigate(`${appBasePath}/dashboard/logout`);
  }
  return Promise.reject({
    message: "Api error",
    statusCode: response.status,
    data: response.body,
  });
}

export function checkStatusForBlob(response: Response): Promise<Blob> {
  // console.log('Response:', response);
  if (response.status >= 200 && response.status < 300) {
    return response.blob();
  }
  // if (response.status === 403) logOutUserOn403Respons();
  if (response.status === 403) {
    // logout();
    navigate(`${appBasePath}/dashboard/logout`);
  }
  return Promise.reject({
    message: "Api error",
    statusCode: response.status,
    data: response.body,
  });
}

export function getAll<T>(urlBase: string, filters: any): Promise<T> {
  let params = "";
  if (filters)
    params = Object.keys(filters)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`)
      .join("&")
      .replace(/%20/g, "+");
  const loginData = {
    method: "get",
    headers: {
      azza: getToken() ?? "" ?? "",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return fetch(`${urlBase}?${params}`, loginData).then(checkStatus) as Promise<T>;
}

export function getById<T>(urlBase: string, itemId: string): Promise<T> {
  const loginData = {
    method: "get",
    headers: {
      azza: getToken() ?? "",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  return fetch(`${urlBase}/${itemId}`, loginData).then(checkStatus) as Promise<T>;
}

export function putById<T>(urlBase: string, item: any): Promise<T> {
  const loginData = {
    method: "put",
    headers: {
      azza: getToken() ?? "",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(item),
  };

  return fetch(`${urlBase}/${item._id}`, loginData).then(checkStatus) as Promise<T>;
}

export function deleteById<T>(urlBase: string, itemId: string): Promise<T> {
  const loginData = {
    method: "delete",
    headers: {
      azza: getToken() ?? "",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  return fetch(`${urlBase}/${itemId}`, loginData).then(checkStatus) as Promise<T>;
}

export function post<T>(urlBase: string, item: any): Promise<T> {
  const loginData = {
    method: "post",
    headers: {
      azza: getToken() ?? "",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(item),
  };

  return fetch(`${urlBase}`, loginData).then(checkStatus) as Promise<T>;
}

export function customGet<T>(url: string): Promise<T> {
  const loginData = {
    method: "get",
    headers: {
      azza: getToken() ?? "",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  return fetch(`${url}`, loginData).then(checkStatus) as Promise<T>;
}

export function customPut<T>(url: string, data: any): Promise<T> {
  const loginData = {
    method: "put",
    headers: {
      azza: getToken() ?? "",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  return fetch(`${url}`, loginData).then(checkStatus) as Promise<T>;
}

export function customPost<T>(url: string, data: any): Promise<T> {
  const loginData = {
    method: "post",
    headers: {
      azza: getToken() ?? "",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  return fetch(`${url}`, loginData).then(checkStatus) as Promise<T>;
}
export function customDelete<T>(url: string, data?: any): Promise<T> {
  const loginData = {
    method: "delete",
    headers: {
      azza: getToken() ?? "",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  return fetch(`${url}`, loginData).then(checkStatus) as Promise<T>;
}
