import { ApiResponse, apiBase, customDelete, customGet, customPost, customPut } from "./utils";

const baseUrl = `${apiBase}/adminApi/faqs`;

export type FaqDataDb = {
  _id: string;
  tip: number;
  continut: string;
  titlu: string;
  isNew?: boolean;
};

export type FaqList = {
  _id: string;
  tip: number;
  titlu: string;
};

export function getAllFaqs(): Promise<ApiResponse<FaqList[]>> {
  return customGet(`${baseUrl}/all`);
}

export function getFaqById(itemId: string): Promise<ApiResponse<FaqDataDb>> {
  return customGet(`${baseUrl}/${itemId}`);
}

export function postNewFaq(data: Omit<FaqDataDb, "_id">): Promise<ApiResponse<string>> {
  return customPost(baseUrl, data);
}

export function putFaq(data: FaqDataDb): Promise<ApiResponse<null>> {
  return customPut(`${baseUrl}/${data._id}`, data);
}

export function deleteFaqById(itemId: string): Promise<ApiResponse<null>> {
  return customDelete(`${baseUrl}/${itemId}`);
}
